"use client";

import React, { ReactNode, useState } from "react";

import styles from './Header.module.scss';
import { MoonFilled, SunFilled } from "@ant-design/icons";

import {
  changeTheme,
  selectTheme,
} from "@/lib/store/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "@/lib/store/hooks";

interface fcProps {
  children?: ReactNode;
  className?: string;
}

const Header: React.FC<fcProps> = ({ children, className = "" }) => {
  // const [count, setCount] = useState(0);
  const dispatch = useAppDispatch();
  const theme = useAppSelector(selectTheme);

  const handleClick = () => {
    // setCount(count + 1);
    // 可以在这里添加更多的点击后逻辑
    if (theme === "light") {
      dispatch(changeTheme!("dark"));
    } else {
      dispatch(changeTheme!("light"));
    }
  };

  return (
    <div className={`${styles.header} ${className}`}>
      <div className={styles.header__left}>left</div>

      <div className={styles.header__right} onClick={handleClick}>
        <div className={styles.header__theme}>
          {theme as React.ReactNode}
          {theme === "light" ? <SunFilled /> : <MoonFilled />}
        </div>
      </div>
    </div>
  );
};

export default Header;