"use client";

import React, { ReactNode, useState } from "react";

import styles from "./Member.module.scss";
import Link from "next/link";
import { usePathname } from "next/navigation";

interface fcProps {
  children?: ReactNode;
  className?: string;
}

const SearchEngine = [
  {
    name: "百度",
    url: "/search/baidu",
  },
  {
    name: "搜狗",
    url: "/search/sogou",
  },
  {
    name: "360搜索",
    url: "/search/so",
  },
  {
    name: "必应中国",
    url: "/search/bing",
  },
  {
    name: "神马搜索",
    url: "/search/sm",
  }
];

const AiEngine = [
  {
    name: "百度",
    url: "/search/baidu",
  },
  {
    name: "搜狗",
    url: "/search/sogou",
  },
  {
    name: "360搜索",
    url: "/search/so",
  },
  {
    name: "必应中国",
    url: "/search/bing",
  },
  {
    name: "神马搜索",
    url: "/search/sm",
  }
];

const Member: React.FC<fcProps> = ({children, className = ""}) => {
  const pathname = usePathname()

  console.log(63, "Member pathname", pathname);
  const [_, group] = pathname?.split("/");
  let engineList: any[] = [];
  if (group === "search") {
    engineList = SearchEngine;
  } else if (group === "ai") {
    engineList = AiEngine;
  }
  //
  // const handleClick = (name: string) => {
  //   setCurrent(name);
  //   // 可以在这里添加更多的点击后逻辑
  // };

  return (
    <div className={`${styles.member} ${className}`}>
      <div className={styles.member__body}>
        {engineList.map((item) => (
          <Link
            key={item.name}
            className={`${styles.member__element} ${pathname === item.url ? styles.member__element_current : ""}`}
            href={item.url}
            // onClick={() => handleClick(engine.name)}
          >
            {item.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Member;