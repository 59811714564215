import { createAppSlice } from "@/lib/store/createAppSlice";
import type { PayloadAction, Slice } from "@reduxjs/toolkit";
import { LocalStorageSetItem } from "@/lib/storage";

export interface UserSliceState {
  account: string;
  name: string;
  mobile?: string;
  email?: string;
  age?: number;
  theme?: "light" | "dark";
  token: object;
}
const dark = {
  "colorPrimary": "#1677ff",
  "colorInfo": "#1677ff",
  "colorSuccess": "#52c41a",
  "colorWarning": "#faad14",
  "colorError": "#ff4d4f",
  "colorLink": "#1668dc",
  "colorTextBase": "#ffffff",
  "colorBgBase": "#000000",
  "fontSize": 12,
  "sizeStep": 4,
  "sizeUnit": 4,
  "borderRadius": 4,
  "boxShadow": "none",
  "boxShadowSecondary": "none",
  "wireframe": false
};

const light = {
  "colorPrimary": "#1677ff",
  "colorInfo": "#1677ff",
  "colorSuccess": "#52c41a",
  "colorWarning": "#faad14",
  "colorError": "#ff4d4f",
  "colorLink": "#1677ff",
  "colorTextBase": "#000000",
  "colorBgBase": "#ffffff",
  "fontSize": 12,
  "sizeStep": 4,
  "sizeUnit": 4,
  "borderRadius": 4,
  "boxShadow": "none",
  "boxShadowSecondary": "none",
  "wireframe": false
};

const initialState: UserSliceState = {
  account: "visitor",
  name: "visitor",
  theme: "light",
  token: light,
};

// If you are not using async thunks you can use the standalone `createSlice`.
export const userSlice: Slice<UserSliceState> = createAppSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: (create) => ({
    changeTheme: create.reducer((state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      console.log("68 changeTheme::", state);
      console.log("69 changeTheme::", action.payload);
      console.log("70 changeTheme::", state.theme);
      let payload = (action.payload || "light");
      if (payload === "light" || payload === "dark") {
        if (typeof window !== "undefined") {
          LocalStorageSetItem("THEME", payload);
        }
        state.theme = payload;
        if (payload === "light") {
          state.token = light;
        } else {
          state.token = dark;
        }
      }
      console.log("84 changeTheme::", state.theme);
    }),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    selectTheme: (state) => state?.theme,
    selectToken: (state) => state?.token,
  },
});

// Action creators are generated for each case reducer function.
export const {
  changeTheme,
} = userSlice.actions;

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
  // @ts-ignore
  selectTheme,
  // @ts-ignore
  selectToken,
} = userSlice.selectors;
