"use client";

import React, { ReactNode } from "react";
import type { MenuProps } from 'antd';

import styles from './Group.module.scss';
import { usePathname } from "next/navigation";
import Link from "next/link";

interface fcProps {
  children?: ReactNode;
  className?: string;
}

const Classify = [
  {
    name: "新闻",
    url: "/search/sogou",
  },
  {
    name: "搜索",
    url: "/search/baidu",
  },
  {
    name: "AI",
    url: "/ai/silicon",
  },
  {
    name: "视频",
    url: "/search/bing",
  },
  {
    name: "直播",
    url: "/search/sm",
  },
  {
    name: "游戏",
    url: "/search/game",
  }
];

const Group: React.FC<fcProps> = ({ children, className = "" }) => {

  const pathname = usePathname()

  console.log(47, "Group pathname", pathname);

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
  };

  return (
    <div className={`${styles.group} ${className}`}>

      <div className={styles.group__body}>
        {Classify.map((item) => (
          <Link
            key={item.name}
            className={`${styles.group__element} ${pathname === item.url ? styles.group__element_current : ""}`}
            href={item.url}
          >
            {item.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Group;