/**
 * Author: Toweave
 * Date:  2019/10/08
 * @param: storage
 */
const CookieGetItem = function(sKey) {
  return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null
}
/**
 * Author: Toweave
 * Date:  2019/10/08
 * @return: {boolean}
 */
const CookieSetItem = function(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
  if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
    return false
  }
  let sExpires = ''
  if (vEnd) {
    switch (vEnd.constructor) {
      case Number:
        sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd
        break
      case String:
        sExpires = '; expires=' + vEnd
        break
      case Date:
        sExpires = '; expires=' + vEnd.toUTCString()
        break
    }
  }
  document.cookie = encodeURIComponent(sKey) + '=' + encodeURIComponent(sValue) + sExpires + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '') + (bSecure ? '; secure' : '')
  return true
}
/**
 * @return {boolean}
 */
const CookieHasItem = function(sKey) {
  return (new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=')).test(document.cookie)
}
/**
 * @return {boolean}
 */
const CookieRemoveItem = function(sKey, sPath, sDomain) {
  if (!sKey || !CookieHasItem(sKey)) {
    return false
  }
  document.cookie = encodeURIComponent(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '')
  return true
}
/* optional method: you can safely remove it! */
const CookieKeys = function() {
  const aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '').split(/\s*(?:\=[^;]*)?;\s*/)
  for (let nIdx = 0; nIdx < aKeys.length; nIdx++) {
    aKeys[nIdx] = decodeURIComponent(aKeys[nIdx])
  }
  return aKeys
}

// 保存数据到 sessionStorage
const SessionSetItem = function(sKey, sValue) {
  sessionStorage.setItem(sKey, sValue)
}

// 从 sessionStorage 获取数据
/**
 * @return {string}
 */
const SessionGetItem = function(sKey) {
  return sessionStorage.getItem(sKey)
}

// 从 sessionStorage 删除保存的数据
const SessionRemoveItem = function(sKey) {
  sessionStorage.removeItem(sKey)
}

// 从 sessionStorage 删除所有保存的数据
const SessionClear = function(sKey) {
  sessionStorage.clear()
}
// 获取所有 keys
const SessionKeys = function() {
  return Object.keys(sessionStorage).reduce((acc, cur) => {
    acc.push(cur)
    return acc
  }, [])
}

// 保存数据到 localStorage
const LocalStorageSetItem = function(sKey, sValue) {
  localStorage.setItem(sKey, JSON.stringify(sValue))
}

// 从 localStorage 获取数据
const LocalStorageGetItem = function(sKey) {
  return JSON.parse(localStorage.getItem(sKey))
}

// 从 localStorage 删除保存的数据
const LocalStorageRemoveItem = function(sKey) {
  localStorage.removeItem(sKey)
}

// 从 localStorage 删除所有保存的数据
const LocalStorageClear = function(sKey) {
  localStorage.clear()
}
// 获取所有 keys
const LocalStorageKeys = function() {
  return Object.keys(localStorage).reduce((acc, cur) => {
    acc.push(cur)
    return acc
  }, [])
}

export {
  CookieGetItem,
  CookieSetItem,
  CookieRemoveItem,
  CookieHasItem,
  CookieKeys,
  SessionSetItem,
  SessionGetItem,
  SessionRemoveItem,
  SessionClear,
  SessionKeys,
  LocalStorageSetItem,
  LocalStorageGetItem,
  LocalStorageRemoveItem,
  LocalStorageClear,
  LocalStorageKeys
}
