"use client";

import React, { ReactNode } from "react";

import styles from "./Navigation.module.scss";
import Group from "./Group";
import Member from "./Member";
import Image from "next/image";
import Link from "next/link";
import Logo from './logo-pmomp.svg';

interface fcProps {
  children?: ReactNode;
  className?: string;
}

const Navigation: React.FC<fcProps> = ({children, className = ""}) => {
  return (
    <div className={`${styles.navigation} ${className}`}>
      <div className={styles.navigation__header}>
        <Link href={"/"}>
          <Logo className={styles.navigation__logo}></Logo>
        </Link>
      </div>
      <div className={styles.navigation__body}>

        <div className={styles.navigation__left}>
          <Group></Group>
        </div>

        <div className={styles.navigation__right}>
          <Member></Member>
        </div>
      </div>
    </div>
  );
};

export default Navigation;