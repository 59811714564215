"use client";

import React, { ReactNode, useEffect } from "react";
import { App, theme } from 'antd';

// import Navbar from "@/components/navbar/Navbar";
// import Footer from "@/components/footer/Footer";
import styles from './Frame.module.scss';
import Main from "./Main";
import Navigation from "./navigation/Navigation";
import { useAppDispatch, useAppSelector } from "@/lib/store/hooks";
import { changeTheme, selectTheme } from "@/lib/store/features/user/userSlice";
import { usePathname } from "next/navigation";
import { LocalStorageGetItem } from "@/lib/storage";

interface fcProps {
  children: ReactNode;
  className?: string;
}

const { useToken } = theme;

const Frame: React.FC<fcProps> = ({ children, className = "" }) => {
  const dispatch = useAppDispatch();
  const { token } = useToken() || { token: {} };
  const theme = useAppSelector(selectTheme);
  // console.log(21, "Frame", token);
  // console.log(21, "theme", theme);
  let current = "light";
  if (typeof window !== "undefined") {
    current = LocalStorageGetItem("THEME")
  }
  useEffect(() => {
    if (current !== theme) {
      dispatch(changeTheme!(current));
    }
  })


  const pathname = usePathname()

  console.log(30, "pathname", pathname);

  return (
    <App>
      <div
        id={"Frame"}
        className={`${styles.frame} ${className}`}
        data-theme={theme}
        style={{
        backgroundColor: token.colorBgBase,
        color: token.colorTextBase,
        fontSize: token.fontSize,
      }}>
        <div className={styles.frame__left}>
          <Navigation></Navigation>
        </div>

        <div className={styles.frame__center}>
          <Main>{children}</Main>
        </div>

        <div className={styles.frame__right}>
          right
        </div>
      </div>
    </App>
  );
};

export default Frame;