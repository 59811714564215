"use client";

import React, { ReactNode } from "react";

import styles from './Content.module.scss';

interface fcProps {
  children: ReactNode;
  className?: string;
}

const Content: React.FC<fcProps> = ({ children, className = "" }) => {
  return (
    <div className={`${styles.content} ${className}`}>
      <div className={`${styles.content__left} scrollbar-thin`}>
        {children}
      </div>

      <div className={styles.content__right}>
          status
      </div>
    </div>
  );
};

export default Content;