"use client";

import React from "react";
import { useServerInsertedHTML } from "next/navigation";

import { createCache, StyleProvider, extractStyle } from "@ant-design/cssinjs";
import type Entity from "@ant-design/cssinjs/es/Cache";
import { ConfigProvider, } from "antd";
import type { AliasToken } from "antd/es/theme/interface"; // 引入AliasToken类型
import { AntdRegistry } from "@ant-design/nextjs-registry";
import { selectToken, } from "@/lib/store/features/user/userSlice";
import { useAppSelector } from "@/lib/store/hooks";
import theme from "@/theme/theme";

export const AntdProvider = ({children}: React.PropsWithChildren) => {
  const cache = React.useMemo<Entity>(() => createCache(), []);
  const token = useAppSelector(selectToken);

  // useServerInsertedHTML(() => (
  //   <style id="antd" dangerouslySetInnerHTML={{ __html: extractStyle(cache, true) }}
  //   />
  // ));

  return (
    <StyleProvider cache={cache}>
      <ConfigProvider theme={{
        ...theme,
        // @ts-ignore
        token: token,
      }}>
        <AntdRegistry>{children}</AntdRegistry>
      </ConfigProvider>
    </StyleProvider>
  );
};

