"use client";

import React, { ReactNode } from "react";

import styles from './Main.module.scss';

import Content from "./Content";
import Header from "./Header";

interface fcProps {
  children: ReactNode;
  className?: string;
}

const Main: React.FC<fcProps> = ({ children, className = "" }) => {
  return (
    <main className={`${styles.main} ${className}`}>
      <div className={styles.main__header}>
        <Header />
      </div>

      <div className={styles.main__body}>
          <Content>{children}</Content>
      </div>

      <div className={styles.main__footer}>
        footer
      </div>
    </main>
  );
};

export default Main;