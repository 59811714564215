import { theme as antTheme, ThemeConfig } from "antd";

// const dark = {
//   "colorPrimary": "#1677ff",
//   "colorInfo": "#1677ff",
//   "colorSuccess": "#52c41a",
//   "colorWarning": "#faad14",
//   "colorError": "#ff4d4f",
//   "colorLink": "#1668dc",
//   "colorTextBase": "#ffffff",
//   "colorBgBase": "#000000",
//   "fontSize": 12,
//   "sizeStep": 4,
//   "sizeUnit": 4,
//   "borderRadius": 4,
//   "wireframe": false
// };

const theme: ThemeConfig = {
  token: {
    "colorPrimary": "#1677ff",
    "colorInfo": "#1677ff",
    "colorSuccess": "#52c41a",
    "colorWarning": "#faad14",
    "colorError": "#ff4d4f",
    "colorLink": "#1677ff",
    "colorTextBase": "#000000",
    "colorBgBase": "#ffffff",
    "fontSize": 12,
    "sizeStep": 4,
    "sizeUnit": 4,
    "borderRadius": 4,
    "boxShadow": "none",
    "boxShadowSecondary": "none",
    "wireframe": false
  },
  "components": {
    "Button": {
      "defaultShadow": "none",
      "primaryShadow": "none",
      "dangerShadow": "none",
    }
  },
  cssVar: true,
  // hash 是 Ant Design 5.0 以来的特性之一，其功能是为每一份主题计算一个独特的 hash 值，并将其用在组件的 class 上，用于隔离主题样式。
  // 但是启用了 CSS 变量之后，相同 antd 版本下的组件样式将不会随着 token 变化而改变 —— 因为我们用 CSS 变量填充了样式中的动态部分。所以如果你的应用中只存在一个版本的 antd，你可以选择关闭 hash 来进一步减
  hashed: false,
  algorithm: [antTheme.compactAlgorithm, antTheme.defaultAlgorithm], // 选择紧凑算法和默认算法
  // algorithm: [antTheme.compactAlgorithm, antTheme.darkAlgorithm], // 选择紧凑算法和暗黑算法
  // algorithm: antTheme.compactAlgorithm,
  // algorithm: antTheme.defaultAlgorithm,

};
export default theme;